let PAGE_SIZE = 4;
let CURRENT_PAGE = 1;
let NUM_PAGES = 0;
let NUM_QUESTIONS = 0;
let NUM_COMPLETED = 0;

const URLPARAMETER_PAGE = 'page';

export default class Questionaire {

    constructor(elm) {       
        this.$container = $(elm);
        this.$questions = $('.question');
        this.$form = this.$container.closest('form');
        this.sessionid = this.$container.attr('data-sessionid');
        this.init();
        this.setEventListners();
        this.setObservers();
    }

    //Module Logic
    setEventListners() {
        this.$container.find('.question-scaleItem-input').on('change', this.onScaleItemChange.bind(this));
        this.$container.find('.question-multiWordItem-input').on('click', this.onMultiWordItemClick.bind(this));
        this.$container.find('.questionaire-back').on('click', this.onPreviousClick.bind(this));
        this.$container.find('.questionaire-next').on('click', this.onNextClick.bind(this));
        this.$container.find('.questionaire-submit').on('click', this.onSubmitClick.bind(this));

        global.$window
            .on('changed.zf.mediaquery', this.onBreakpointChange.bind(this));
    }

    setObservers() {
        this.observeQuestionaireHeader();
    }

    init() {
        this.setStylesForSentinel();
        NUM_QUESTIONS = this.$questions.length;

        this.initQueryStringValues();
        this.create();
    }

    create() {
        if (Foundation.MediaQuery.atLeast('medium')) {
            PAGE_SIZE = this.$container.data('pagesize');
        } else {
            PAGE_SIZE = 1;
        }

        //this.$container.attr('data-pagesize', PAGE_SIZE).data('pagesize', PAGE_SIZE);
        this.questionGroups = this.groupQuestions();

        this.setNumberOfPages();
        this.setNumberOfCompletedQuestions();
        this.goToPage(CURRENT_PAGE);
        this.updateProgress();
        this.setNextButtonState();
    }

    destroy() {
        //nothing to destroy, create overwrites all paramters
    }

    //Module Logic

    //Module Business Logic
    groupQuestions() {
        let nr = 1;
        let groups = [];
        let currentGroup = [];
        let count = this.$questions.length;

        this.$questions.each(function (i, elm) {

            if (nr <= PAGE_SIZE) {
                currentGroup.push($(elm));
            }

            if (nr === PAGE_SIZE || (i + 1) === count) {
                groups.push(currentGroup.slice(0));
                currentGroup = [];
                nr = 0;
            }

            nr++;
        });

        return groups;
    }

    goToPage(page, scrollToTop=false) {
        page = (page > NUM_PAGES) ? NUM_PAGES : page;
        page = (page < 1) ? 1 : page;

        if (page === NUM_PAGES) {
            this.showSubmit();
        } else {
            this.hideSubmit();
        }

        if (page === 1) {
            this.disableBackButton();
        } else {
            this.enableBackButton();
        }

        if (typeof this.questionGroups[page - 1] !== "undefined") {
            this.setNextButtonState();
            this.hidePages();

            for (let $elm of this.questionGroups[page - 1]) {
                $elm.removeClass('question--hidden');
            }

            CURRENT_PAGE = page;

            this.updateQueryString();

            if (scrollToTop) {
                this.scrollToTop();
            }
        }
    }

    currentPageIsCompleted() {
        let answeredIndex = 0;
        var numOfQuestionsOnThisPage = this.questionGroups[CURRENT_PAGE - 1];

         //loop through each question in this page and count the number of answered qestions    
        var questionGroup = this.questionGroups[CURRENT_PAGE - 1];
        for (let $question of questionGroup) {            
            if ($question[0].className.includes("multiword5")) {
                /*multiword question. Only answered if exact 5 checkboxes are checked*/
                var multiwordCheckedForThisQuestion = $question.find('.question-multiWordItem-input:checked');
                if (multiwordCheckedForThisQuestion.length == 5) {
                    answeredIndex++;
                }
            } else {
               /* question is answered when at least one checkbox is checked*/
                var scaleInputs = $question.find('.question-scaleItem-input:checked');
                if (scaleInputs.length > 0) {
                    answeredIndex++;
                }
            }
        }

        var completed = false;
        if (numOfQuestionsOnThisPage < PAGE_SIZE) {
            completed = (answeredIndex === numOfQuestionsOnThisPage)
        } else {
            completed = (answeredIndex === PAGE_SIZE)
        }

        return completed;
    }

    getPercentage() {
        let perc = Math.ceil((NUM_COMPLETED * 100) / NUM_QUESTIONS);
        if (perc == 0) perc = 1;
        if (perc == 100) perc = 99;
        return perc;
    }

    setNumberOfCompletedQuestions() {

        //count the number of completed regular (likert scale) questions
        NUM_COMPLETED = this.$container.find('.question-scaleItem-input:checked').length;

        //count number of completed multiword questions. Multiword question is only completed if exactly 5 checkboxes are checked in that question
        var completedMultiWordQuestions = 0;
        for (let questionGroup of this.questionGroups) {
            for (let question of questionGroup) {               
                var checkedMultiWordsInOneQuestion = question.find('.question-multiWordItem-input:checked');
                if (checkedMultiWordsInOneQuestion.length == 5) {
                    completedMultiWordQuestions++;
                }
            }
        }      
        NUM_COMPLETED = NUM_COMPLETED + completedMultiWordQuestions;

    }

    
    

    setNumberOfPages() {
        NUM_PAGES = this.questionGroups.length;
    }
    //Module Business Logic

    //QUERYSTRING
    initQueryStringValues() {
        var urlSearchParams = this.readQueryString();

        CURRENT_PAGE = this.readQueryStringValueInt(urlSearchParams, URLPARAMETER_PAGE) || CURRENT_PAGE;
    }

    updateQueryString() {
        var urlSearchParams = this.readQueryString();
        urlSearchParams.set(URLPARAMETER_PAGE, CURRENT_PAGE);
        history.pushState(null, null, `?${urlSearchParams.toString()}`);
    }


    readQueryString() {
        return new URLSearchParams(window.location.search);
    }

    readQueryStringValueInt(urlSearchParams, name) {
        if (urlSearchParams.has(name)) {
            let nr = parseInt(urlSearchParams.get(name));

            if (nr !== NaN) {
                return nr;
            }
        }

        return null;
    }
    //QUERYSTRING

    //UI
    setStylesForSentinel() {
        this.$sentinel = $('.questionaire-header--sentinel');
        this.$sentinel.css({
            height: '5px',
            background: 'transparent',
            width: '100%',
            marginTop: '-5px'
        });
    }

    scrollToTop() {
        if (PAGE_SIZE > 1) {
            let offsetTop = $('.questionaire-header--sentinel').offset();
            global.$window.scrollTo(offsetTop.top, 600);
        }
    }

    scrollToBottom() {
        if (PAGE_SIZE > 1) {
            global.$window.scrollTo($(document).height(), 200);
        }
    }

    scrollToQuestion($elm) {
        if (PAGE_SIZE > 1) {
            let offsetTop = $elm.offset().top;

            let headerRect = $.rectangle($('.questionaire-header')[0]);
            let questionRect = $.rectangle($elm[0]);
            let questionPadding = parseInt($elm.css('padding-bottom').replace('px', ''));
            let questionBorderSize = parseInt($elm.css('border-bottom-width').replace('px', ''));

            var vh = $.viewportH();
            var hh = headerRect.height;
            var qh = questionRect.height + questionPadding + questionBorderSize;
            var wp = vh - hh;
            var off = wp - qh;

            //global.$window.scrollTo(offsetTop - hh - off, 400);
            global.$window.scrollTo(offsetTop - (hh + 100), 400);
        }
    }

    updateProgress() {
        let percentage = this.getPercentage();
        $('.progress').each((i, elm) => {
            let $elm = $(elm);

            $elm.find('.progress-count').text(`${percentage}%`);
            $elm.find('.progress-fill')
                .attr('aria-valuenow', percentage)
                .css({ width: `${percentage}%` });
        });
    }

    hidePages() {
        this.$questions.addClass('question--hidden');
    }

    showSubmit() {
        this.$container.find('.questionaire-next').addClass('questionaire-next--hidden');
        this.$container.find('.questionaire-submit').removeClass('questionaire-submit--hidden');
    }

    hideSubmit() {
        this.$container.find('.questionaire-next').removeClass('questionaire-next--hidden');
        this.$container.find('.questionaire-submit').addClass('questionaire-submit--hidden');
    }

    disableBackButton() {
        this.$container.find('.questionaire-back')
            .addClass('button--disabled')
            .addClass('questionaire-back--disabled');
    }

    enableBackButton() {
        this.$container.find('.questionaire-back')
            .removeClass('button--disabled')
            .removeClass('questionaire-back--disabled');
    }

    disableNextButton() {
        this.$container.find('.questionaire-next')
            .addClass('button--disabled')
            .addClass('questionaire-next--disabled');
    }

    enableNextButton() {
        this.$container.find('.questionaire-next')
            .removeClass('button--disabled')
            .removeClass('questionaire-next--disabled');
    }

    setNextButtonState() {
        if (this.currentPageIsCompleted()) {
            this.enableNextButton();
            this.removePageNotCompletedWarning();
        } else {
            this.disableNextButton();
        }
    }
    //UI

    //VALIDATION
    isComplete() {
        return (NUM_COMPLETED === NUM_QUESTIONS);
    }

    isValid() {
        //extend with more validation methods when needed.
        return this.isComplete();
    }

    showWarnings() {
        //list all warnings here and check if they need to show in the methods
        this.showCompletionWarning();
    }

    showCompletionWarning() {
        //check if the warning needs to display, and then display it
        if (!this.isComplete()) {
            this.$container.find('.questionaire-completionWarning').addClass('questionaire-completionWarning--active');
        }
    }

    showPageNotCompletedWarning() {
        var warning = this.$container.find('.questionaire-pageNotCompletedWarning');
        warning.css("display", "block");
    }
    removePageNotCompletedWarning() {
        var warning = this.$container.find('.questionaire-pageNotCompletedWarning');
        warning.css("display", "none");
    }
    //VALIDATION

    //STORAGE
    persistQuestion(instrumentItemID, selectedInstrumentItemOptionIDs, customValue) {
        $.ajax({
            url: '/api/sessions/' + this.sessionid + '/savesessionanswers',  
            type: 'post',
            contentType: 'application/json',
            data: JSON.stringify({
                instrumentItemID: instrumentItemID,
                instrumentItemOptionIDs: selectedInstrumentItemOptionIDs,
                customValue: customValue
            }),
            success: function (result) {
                result = global.resolveReferences(result);

                //console.log(result);
            },
            error: function(e) {
                //error handling?
                console.error(e.statusText);
            }
        });
    }
    //STORAGE

    //Event Handling
    observeQuestionaireHeader() {
        var $target = $('.questionaire-header');

        let options = {
            root: null,
            rootMargin: '0px',
            threshold: [0, 1]
        }

        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                let sign = Math.sign(entry.boundingClientRect.y);

                if (sign < 0) {
                    $target.addClass('questionaire-header--isSticky');
                } else {
                    $target.removeClass('questionaire-header--isSticky');
                }
            });
        }, options);

        observer.observe(this.$sentinel[0]);
    }

    onScaleItemChange(event) {
        this.setNumberOfCompletedQuestions();

        this.updateProgress();
        var $ClickedCheckbox = $(event.target);
        let instrumentItemID = $ClickedCheckbox.attr('name');
        let selectedOptionID = $ClickedCheckbox.attr('value');

        //in the likertyscale type of questions there is always only one answer, but we must put it in an array (the persistQuestion needs an array for the multiword questions).
        const checkedInstrumentItemOptionIDs = [];
        checkedInstrumentItemOptionIDs.push(selectedOptionID);

        //save the selected option in the DB
        this.persistQuestion(instrumentItemID, checkedInstrumentItemOptionIDs, "");

        var $nextQuestion = $(event.target).closest('.question').next().not('.question--hidden');
        if ($nextQuestion.length > 0) {
            this.scrollToQuestion($nextQuestion);
        } else {
            this.scrollToBottom();
        }

        this.setNextButtonState();
    }


    onMultiWordItemClick(event) {
        this.setNumberOfCompletedQuestions();

        var clickedCheckbox = event.currentTarget;      
        let instrumentItemID = clickedCheckbox.name

        //first remove all warning labels for this insrumentitem
        var warninglabels = document.querySelectorAll('label[name="warninglabel"][id="' + instrumentItemID + '"]');
        for (let i = 0; i < warninglabels.length; i++) {
            warninglabels[i].innerText = "";
        }
        //check or uncheck the clicked checkbox       
        if (clickedCheckbox.checked) {
            clickedCheckbox.setAttribute("checked", "checked");
        } else {
            clickedCheckbox.removeAttribute("checked");
        }
        //save the ids of all the checked checkboxes in an array     
        const checkedOptionIDs = [];
        var selectedCheckboxes = document.querySelectorAll('input[name="' + instrumentItemID + '"][checked="checked"]');
        for (const c of selectedCheckboxes) {
            checkedOptionIDs.push(c.value);
        }
        //if more than 5 checkboxes are selected, do not save and show a warning
        if (selectedCheckboxes.length > 5) {
            var closestDiv = clickedCheckbox.closest("div");
            clickedCheckbox.checked == false;
            var warninglabel = closestDiv.querySelector('label[name="warninglabel"]');
            warninglabel.innerText = "selecteer niet meer dan 5 checkboxes";            
        } else {
            //save the checked checkboxes 
            this.persistQuestion(instrumentItemID, checkedOptionIDs, "");
            this.setNextButtonState();
        }

      

    }

   



    onPreviousClick(event) {
        event.preventDefault();

        this.goToPage(CURRENT_PAGE - 1);
    }

    onNextClick(event) {
        event.preventDefault();

        if (this.currentPageIsCompleted()) {
            this.removePageNotCompletedWarning();
            this.goToPage(CURRENT_PAGE + 1, true);
        } else {
            this.showPageNotCompletedWarning();
        }
    }

    onSubmitClick(event) {
        event.preventDefault();
        
        if (this.isValid()) {
            this.$form.submit();
        } else {
            this.showWarnings();
        }
    }

    onBreakpointChange(event, newSize, oldSize) {
        if (
            (oldSize === "medium" && newSize === "small") ||
            (oldSize === "small" && newSize === "medium")
        ) {
            this.create();
        }
    }
    //Event Handling


    
     
     





}